.shadow-z-1 {
  box-shadow:
    0 1px 6px 0 rgba(0, 0, 0, 0.12),
    0 1px  6px 0 rgba(0, 0, 0, 0.12);
}

.shadow-z-1-hover {
  box-shadow:
    0 5px 11px 0 rgba(0, 0, 0, 0.18),
    0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.shadow-z-2 {
  box-shadow:
    0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.shadow-z-3 {
  box-shadow:
    0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.shadow-z-4 {
  box-shadow:
    0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.shadow-z-5 {
  box-shadow:
    0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
