.alert {
    border: 0px;
    border-radius: 0;

    .generic-variations(~"", @darkbg-text, {
        background-color: @material-color;
        color: @text-color;

        a, .alert-link {
            color: @text-color;
        }
    });

    &-info, &-danger, &-warning, &-success {
        color: @darkbg-text;
    }

    &-default {
        a, .alert-link {
            color: @lightbg-text;
        }
    }
}
