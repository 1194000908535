body, .container, .container-fluid {

  .well.well-sm {
    padding: 10px;
  }
  .well.well-lg {
    padding: 26px;
  }
  .well, .jumbotron {

    background-color: #fff;
    padding: 19px;
    margin-bottom: 20px;
    .shadow-z-2();
    border-radius: 2px;
    border: 0;
    p {
      font-weight: 300;
    }
    .variations(~"", background-color, #FFF);
  }
}
